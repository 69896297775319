.ResizeablePanelHandle {
  @apply h-full;
  @apply w-full;
  @apply transition-colors;
  @apply duration-500;

  &:hover {
  }

  &:active {
  }
}
