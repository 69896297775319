.SelectInput--isOpen {
  @apply ring-2;
  @apply ring-gray3;
  @apply border-gray6;
}

.SelectInput--isDisabled {
  @apply cursor-not-allowed;
  @apply bg-gray3;
  @apply text-gray5;

  &:hover {
    @apply border-gray3;
  }
}

.SelectInputPlaceholder {
  @apply opacity-50;
  color: var(--inputPlaceholderColor);
}

.SelectInputRightElementContainer {
  @apply absolute;
  @apply right-0;
  @apply top-0;
  @apply flex;
  @apply h-full;
  @apply items-center;
  @apply justify-center;
}

.SelectInputArrow {
  @apply flex;
  @apply h-full;
  @apply items-center;
  @apply justify-center;
  @apply text-lg;
  @apply cursor-pointer;
  @apply mr-3;
}

.SelectInput-md {
  @apply h-10;
  @apply text-sm;
  @apply pl-3;
}


