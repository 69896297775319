.TabsNavigationItem {
  @apply relative;
  @apply cursor-pointer;
  @apply whitespace-nowrap;
  @apply text-center;
  @apply h-full;
  @apply px-5;
  @apply py-3;
  @apply text-sm;
  @apply font-medium;
  @apply opacity-60;
}

.TabsNavigationItem--isFitted {
  @apply flex-1;
}

.TabsNavigationItem--isActive {
  @apply opacity-100;
}
