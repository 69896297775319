.Modal__Container {
  @apply w-screen;
  @apply flex;
  @apply overflow-y-auto;
  @apply fixed;
  @apply top-0;
  @apply left-0;
  @apply p-4;
  @apply md:p-10;
  @apply h-screen;
}

.Modal__Container--isCentered {
  @apply items-start;
}

.Modal__Container--isFullWidth {
  @apply items-start;
  @apply items-center;
}

.Modal__Container--outsideScroll {
  @apply overflow-auto;
}
