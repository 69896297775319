.LayoutDashboard {
  @apply flex;
  @apply flex-col;
  @apply h-screen;
  @apply overflow-hidden;
}

.LayoutDashboard__Body {
  @apply flex;
  @apply flex-1;
  @apply overflow-hidden;
}