.TableWrapper {
  @apply flex;
  @apply flex-col;
  @apply flex-1;
  @apply w-full;
  @apply relative;
  @apply overflow-hidden;
}

.TableHeaderCell__resizer {
  @apply absolute;
  @apply top-0;
  right: 0;
  width: 2px;
  @apply h-full;
  // @apply opacity-0;
  background-color: red;

  // &:hover {
  //   @apply opacity-100;
  // }
}

.TableBody {
  @apply flex-1;
  @apply overflow-x-hidden;
  @apply overflow-y-scroll;
}

.TableRow--isClickable {
  @apply cursor-pointer;
}

// .TableRow--isFocused {
//   background-color: var(--table-row-background--hover);
// }

.TableCell--Checkbox {
  width: 52px;
}
