@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  background-color: var(--backgroundColor);
  color: var(--textColorPrimary);
}

.text-muted {
  color: var(--textColorMuted);
}

:root {
  --space0\.5: 2px;
  --space1\.5: 6px;
  --space2\.5: 10px;
}

.ScrollAreaRoot {
  --scrollbar-size: 10px;
}

.ScrollAreaScrollbar {
  display: flex;
  /* ensures no selection */
  user-select: none;
  /* disable browser handling of all panning and zooming gestures on touch devices */
  touch-action: none;
  padding: 2px;
  // background: red;
  transition: background 160ms ease-out;
}

.ScrollAreaScrollbar:hover {
  background: var(--blackA8);
}

.ScrollAreaScrollbar[data-orientation="vertical"] {
  width: var(--scrollbar-size);
}

.ScrollAreaScrollbar[data-orientation="horizontal"] {
  flex-direction: column;
  height: var(--scrollbar-size);
}

.ScrollAreaThumb {
  flex: 1;
  background: var(--mauve10);
  border-radius: var(--scrollbar-size);
  position: relative;
}

.ScrollAreaThumb::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-width: 44px;
  min-height: 44px;
}

.ScrollAreaCorner {
  background: var(--blackA8);
}

.react-datepicker-popper {
  min-width: 330px;
}

.noFooterTimePick .ant-picker-footer {
  display: none;
}

.adjustFooterOkBtn .ant-btn {
  color: var(--textColorPrimary);
}
